body {
  background-image: url(./assets/images/bg.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

th:first-child {
  width: 80px
}

.container {
  background-color: lightslategray;
  max-width: 700px;
  opacity: 0.9;
  margin: 3% auto;
  padding: 10px;
}

img.logo {
  display: block;
  margin: auto;
  width: 200px;
}

img.split {
  height: 50px;
  width: 50px;
}

.split-label {
  font-weight: bolder;
}

td.split-current {
  font-family: monospace !important;
  font-size: 16px;
  color: navy;
  font-weight: bold;
}

td.split-prev {
  font-family: monospace !important;
  font-size: 16px;
  color: black;
  font-weight: bold;
}

td.split-best {
  font-family: monospace !important;
  font-size: 16px;
  color: gold;
  font-weight: bold;
}

.alert {
  font-family: monospace !important;
  font-weight: bold;
  margin-bottom: unset;
}

.promo {
  position: fixed;
  bottom: 0;
  background-color: lightgray;
  left: 0;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
}

.promo a {
  color: darkmagenta;
}

